import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component'
import { HomeComponent } from './home/home.component'
import { RegisterComponent } from './pages/register.component'
import { LoginComponent } from './pages/login.component'
import { ForgotPasswordComponent } from './pages/forgotpassword.component'
import { AuthGuardService as AuthGuard } from './shared/auth-guard.service';
import { VerificationComponent } from './pages/verification.component';
// Layouts
import { FullLayoutComponent } from './layouts/full-layout.component';

export const routes: Routes = [
  {
    path: '',
    component: FullLayoutComponent,
    data: {
      title: 'YÖS'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: HomeComponent,
        data: {
          title: ''
        }
      },
      {
        path: 'sorgula',
        pathMatch: 'full',
        component: VerificationComponent,
        data: {
          title: 'Sonuç Doğrulama'
        }
      },
      {
        path: 'bilgi',
        loadChildren: './information/information.module#InformationModule'
      },
      {
        path: 'aday',
        loadChildren: './candidate/candidate.module#CandidateModule',
        canActivate: [AuthGuard]

      },
      {
        path: 'yonetim',
        loadChildren: './management/management.module#ManagementModule',
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'kayit',
    component: RegisterComponent,
    data: { title: 'Kayıt' }
  },
  {
    path: 'giris',
    component: LoginComponent,
    data: {
      title: 'Giriş'
    }
  },
  {
    path: 'sifremiunuttum',
    component: ForgotPasswordComponent,
    data: {
      title: 'Şifremi Unuttumn'
    }
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
