import { NgModule, ModuleWithProviders} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [
    CommonModule,
    TranslateModule
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders{
    return {
      ngModule: CoreModule
    }
  }
}
