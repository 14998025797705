import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { System, AccTypes } from './../constants/constants';
import { CookieService } from 'ngx-cookie-service';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css']
})
export class LoginComponent {
   emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
   loginForm;
   emailFocus;
   passwordFocus;
   wrongCredentials;
   isAdmin:boolean = false;
   system_error_msg: string;
   system_error_title: string;
   unverified_email_msg: string;
   unverified_email_title: string;
  
   constructor(private http: HttpClient, private cookieService: CookieService, private translate: TranslateService, private router: Router, private toastr: ToastrService) {
     this.translate.get('system_error_msg').subscribe((res:string)=>{
       this.system_error_msg = res;
     });

     this.translate.get('system_error_title').subscribe((res:string)=>{
       this.system_error_title = res;
     });

     this.translate.get('unverified_email_msg').subscribe((res:string)=>{
       this.unverified_email_msg = res;
     });

     this.translate.get('unverified_email_title').subscribe((res:string)=>{
       this.unverified_email_title = res;
     });
   }
   ngOnInit() {
     if (this.cookieService.check('accType') && this.cookieService.get('accType') != AccTypes.Unknown)
       this.router.navigate(['/']);
     
     this.loginForm = new FormGroup({
        email: new FormControl("", Validators.compose([Validators.required,Validators.pattern(this.emailPattern)])),
        password: new FormControl("", [Validators.required, Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/)])
     });

     this.loginForm.get('email').valueChanges.subscribe(emailVal=>{
       var _isAdmin = false;
       if (emailVal.endsWith("@erzurum.edu.tr"))
         _isAdmin = true;

       if (this.isAdmin != _isAdmin) {
         this.isAdmin = _isAdmin;
         var validators = [Validators.required];
         if (!_isAdmin)
           validators.push(Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/));

         var password = this.loginForm.get("password");
	 password.setValidators(validators);
	 password.updateValueAndValidity();
       }
     });
   }
  
   isInvalid(ctl: string){
     var ctrl = this.loginForm.get(ctl);
     return ctrl.invalid && (ctrl.dirty || ctrl.touched);
   }
  
   onClickSubmit(data) {
     const req = this.http.post(System.API_URL + '/giris', data)
      .subscribe(
        res => {
          console.log(JSON.stringify(res));
          if (res["result"] == "invalid_credentials")
            this.wrongCredentials = true;
          else if(res["result"] == "success"){
            var account = res["account"];
	    this.cookieService.set('accType', account.type.toString(), 365, '/', 'yos.erzurum.edu.tr', true, 'Lax');
            this.cookieService.set('candidate_no', account.candidate_no, 365, '/', 'yos.erzurum.edu.tr', true, 'Lax');
            this.cookieService.set('photo', account.photo==undefined?null:account.photo, 365, '/', 'yos.erzurum.edu.tr', true, 'Lax');
            this.cookieService.set('name', account.name, 365, '/', 'yos.erzurum.edu.tr', true, 'Lax');
            this.cookieService.set('surname', account.surname, 365, '/', 'yos.erzurum.edu.tr', true, 'Lax');
            this.cookieService.set('email', account.email, 365, '/', 'yos.erzurum.edu.tr', true, 'Lax');
            
            this.router.navigate(['/']);
	  }
          else if(res["result"] == "not_verified_yet")
	    this.toastr.warning(this.unverified_email_msg, this.unverified_email_title);
	  else
	    this.toastr.error(this.system_error_msg, this.system_error_title);
        },
        err => {
          console.log("fail:"+ JSON.stringify(err));
	  this.toastr.error(this.system_error_msg, this.system_error_title);
        }
      );
   }
}
