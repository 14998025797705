import { Component } from '@angular/core';
import {ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>',
  styles: [],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  options;
  constructor(private translate: TranslateService, cookieService: CookieService){
    if (!cookieService.check('lang')) {
      var browserLang = translate.getBrowserLang();
      if (translate.getLangs().includes(browserLang))
      cookieService.set("lang", browserLang, 365, '/', '/', true, 'Lax');
      else 
        cookieService.set("lang", "en", 365, '/', 'yos.erzurum.edu.tr', true, 'Lax');
    }
    translate.setDefaultLang(cookieService.get('lang'));

    this.options = {position: ["top", "right"]};
  }
}
