import { Component, OnInit, HostListener } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { System, AccTypes } from '../constants/constants';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

  authenticated: boolean;
  slide1_url;
  slide2_url;
  slide3_url;
  slide4_url;
  serverUrl;
  windowWidth;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.windowWidth = window.innerWidth;
    this.translateSlides();
  }

  constructor(private cookieService: CookieService, private translate: TranslateService) {
    this.serverUrl = System.SERVER_URL;
    this.translateSlides();

    translate.onDefaultLangChange.subscribe(()=>{
      this.translateSlides();
    });
  }

  translateSlides() {
  this.translate.get(this.windowWidth < 1024 ? 'slide4_sm_url': 'slide4_url').subscribe((res:string)=>{
      this.slide4_url = res;
    });
    this.translate.get(this.windowWidth < 1024 ? 'slide1_sm_url': 'slide1_url').subscribe((res:string)=>{
      this.slide1_url = res;
    });
    this.translate.get(this.windowWidth < 1024 ? 'slide2_sm_url': 'slide2_url').subscribe((res:string)=>{
      this.slide2_url = res;
    });
    this.translate.get(this.windowWidth < 1024 ? 'slide3_sm_url': 'slide3_url').subscribe((res:string)=>{
      this.slide3_url = res;
    });
  }

  ngOnInit() {
    if (this.cookieService.check('accType') && this.cookieService.get('accType') != AccTypes.Unknown)
      this.authenticated = true;
  }

}
