import { environment } from '../../environments/environment';

export enum AccTypes{
  Candidate="1",
  Management="2",
  Unknown="3"
}

export class System {
  public static get SERVER_URL(): string {
  return environment.production ? "https://yos.erzurum.edu.tr/": "https://yos.erzurum.edu.tr/";
  }
  
  public static get API_URL(): string {
    return System.SERVER_URL + "api";
  };
  
  public static get API_ADAY_URL(): string {
    return System.SERVER_URL + "api/aday";
  };
  
  public static get API_YONETIM_URL(): string {
    return System.SERVER_URL + "api/yonetim";
  };
}
