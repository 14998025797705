import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { System,AccTypes } from './../constants/constants';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  templateUrl: 'forgotpassword.component.html'
})
export class ForgotPasswordComponent {
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  forgotPasswordForm;
  emailSent: boolean;
  email_obj;
  constructor(private http: HttpClient, private cookieService: CookieService,
              translate: TranslateService, private toastr: ToastrService) { }

  ngOnInit() {
  this.cookieService.set('accType', AccTypes.Unknown, 365, '/', 'yos.erzurum.edu.tr', true, 'Lax');
    this.cookieService.delete('photo');
    this.cookieService.delete('name');
    this.cookieService.delete('surname');
    this.cookieService.delete('email');
    
    this.forgotPasswordForm = new FormGroup({
           email: new FormControl("", Validators.compose([Validators.required,Validators.pattern(this.emailPattern)]))
        });
  }
  
  formCtl(ctl: string) { return this.forgotPasswordForm.get(ctl); }
  
  isInvalid(ctl: string, depend: boolean=this.isTouched(ctl)){
     var ctrl = this.forgotPasswordForm.get(ctl);
     return ctrl.invalid && depend;
   }
   
   isTouched(ctl: string){
     var ctrl = this.forgotPasswordForm.get(ctl);
     return (ctrl.dirty || ctrl.touched);
   }
  
  onClickSubmit(data) {
    if (!this.emailSent){
      this.email_obj = {email: data.email};
      this.emailSent=true;
      const req = this.http.post(System.API_ADAY_URL+'/sifremiunuttum', data)
        .subscribe(
          res => {  
          },
          err => {
            this.toastr.error('Sistemde bir hata meydana geldi.', 'Sistem Hatası');
          }
        );
    }
  }
}
