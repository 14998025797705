import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { System } from './../constants/constants';
import {TranslateService} from '@ngx-translate/core';

@Component({
   selector: 'verification',
   templateUrl: './verification.component.html'
})

export class VerificationComponent {
   verificationStatus = "waiting4submit";
   verificationForm;
   
   candidateNum;
   name;
   birthday;
   nationality;
   passportNo;
   examDate;
   resultAnnounceDate;
   numberCorrect;
   numberIncorrect;
   score;

   constructor(private http: HttpClient, translate: TranslateService) { }
   ngOnInit() {
     this.verificationForm = new FormGroup({
       canNo: new FormControl("", Validators.compose([Validators.required, Validators.pattern(/^[\d]{9}$/)])),
       code: new FormControl("", Validators.compose([Validators.required,Validators.pattern(/^[A-Z\d]{10}$/)]))
     });
   }
   onClickSubmit(data) {
     this.http.post(System.API_ADAY_URL+'/sorgula', data)
      .subscribe(
        res => {
          if (res["result"] === "success"){
            var bd = new Date(res["data"].birthdate);
            this.candidateNum = res["data"]["canNo"];
            this.name = res["data"]["name"];
            this.nationality = res["data"]["nationality"];
            this.passportNo = res["data"]["passportNo"];
            this.examDate = res["data"]["examDate"];
            this.resultAnnounceDate = res["data"]["result_announce_date"];
            this.birthday = bd.getDate() + "/" + (bd.getMonth() + 1) + "/" + bd.getFullYear();
            this.numberCorrect = res["data"]["number_correct"];
            this.numberIncorrect = res["data"]["number_incorrect"];
            this.score = res["data"]["score"];
            this.verificationStatus = "success";
          }
          else if (res["result"] === "no_record"){
            this.verificationStatus = "not_found";      
          }
          else if (res["result"] === "date_not_valid"){
            this.verificationStatus = "not_begin";
          }
        },
        err => {
          this.verificationStatus = "error";
        }
      );
   }
  
   formCtl(ctl: string) { return this.verificationForm.get(ctl); }
    
   isInvalid(ctl: string, depend: boolean=this.isTouched(ctl)){
     var ctrl = this.verificationForm.get(ctl);
     return ctrl.invalid && depend;
   }
   
   isTouched(ctl: string){
     var ctrl = this.verificationForm.get(ctl);
     return (ctrl.dirty || ctrl.touched);
   }
}
