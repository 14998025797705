import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { System, AccTypes } from './../constants/constants';
import {Router} from "@angular/router";
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './full-layout.component.html'
})
export class FullLayoutComponent implements OnInit {
  public accTypes = AccTypes;
  public accType: string;
  public candidate_no: string;
  public email: string;
  public photoPath: string;
  public currentLang: string;
  public serverUrl: string;

  constructor(private translate: TranslateService, private http: HttpClient, 
  private cookieService: CookieService, private router: Router){
    this.serverUrl = System.SERVER_URL;
  }

  ngOnInit() {
    if (!this.cookieService.check('accType')){
      this.cookieService.set('accType', AccTypes.Unknown, 365, '/', 'yos.erzurum.edu.tr', true, 'Lax');
    }
    
    this.accType = this.cookieService.get('accType');
    
    if (this.cookieService.check('photo'))
      this.photoPath = this.cookieService.get('photo') !== "null" ? this.serverUrl + this.cookieService.get('photo'):"assets/img/avatars/profile.png";
    
    if (this.cookieService.check('email'))
      this.email = this.cookieService.get('email');
    
    if (this.cookieService.check('candidate_no'))
      this.candidate_no = this.cookieService.get('candidate_no');

    var lang = this.cookieService.get('lang');

    if (!lang)
      lang = 'en';

    this.currentLang = lang == 'tr' ? "Türkçe":"English";
    this.translate.setDefaultLang(lang);
}

  changeLangAs(lang: string) {
    this.cookieService.set('lang', lang, 365, '/', 'yos.erzurum.edu.tr', true, 'Lax');
    this.translate.setDefaultLang(lang);

    this.currentLang = lang == 'tr' ? "Türkçe":"English";
  }
  
  logout(){
    const req = this.http.get(System.API_URL + '/cikis', {})
      .subscribe(
        res => {
          var account = res["account"];
          this.cookieService.set('accType', AccTypes.Unknown, 365, '/', 'yos.erzurum.edu.tr', true, 'Lax');
          this.cookieService.delete('photo');
          this.cookieService.delete('name');
          this.cookieService.delete('surname');
          this.cookieService.delete('email');
          this.cookieService.delete('candidate_no');
          this.router.navigate(['/giris']);
        },
        err => {
          console.log("fail:"+ JSON.stringify(err));
        }
      );
  }
}
