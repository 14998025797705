import {
  Injectable
} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {
  System,
  AccTypes
} from './../constants/constants';

import {
  Router,
  CanActivate
} from '@angular/router';
import {
  Observable
} from 'rxjs/Observable';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private http: HttpClient, public router: Router, private cookieService: CookieService) {}
  canActivate(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.http.post(System.API_URL + '/', {})
      .subscribe(
        res => {
          if (res["result"] == "success"){
            observer.next(true);
            observer.complete();
          }else{
            this.removeSession(observer);
          }
        },
        err => {
          this.removeSession(observer);
        });
    });
  }
  
  removeSession(observer){
  this.cookieService.set('accType', AccTypes.Unknown, 365, '/', 'yos.erzurum.edu.tr', true, 'Lax');
   this.cookieService.delete('photo');
   this.cookieService.delete('name');
   this.cookieService.delete('surname');
   this.cookieService.delete('email');
   this.router.navigate(['/giris']);
   observer.next(false);
   observer.complete();
  }
}
