import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'modal-content',
  template: `
    <div class="modal-content"> 
      <div class="modal-header modal-warning">
        <h4 class="modal-title pull-left">{{title}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul *ngIf="list.length">
          <li *ngFor="let item of list">{{item}}</li>
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Close</button>
      </div>
    </div>
  `
})
export class ModalComponent implements OnInit{
  public title: string;
  public list: any[] = [];
  constructor(public bsModalRef: BsModalRef) {}
 
  ngOnInit() {
    this.list.push('Before time');
    setTimeout(() => {
      this.list.push('After time, Looks like its taking some time to bind content data...Before time data missed - check ngOnInit() ');
    }, 2000);
  }
}